import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { PageOrigins, RouteLinkConstants } from 'src/app/app.constants';
import { NavigationParamsService } from 'src/app/modules/shared/services/navigation-params-service';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';

@Component({
  selector: 'app-no-subscription',
  templateUrl: './no-subscription.component.html',
  styleUrls: ['./no-subscription.component.scss']
})
export class NoSubscriptionComponent implements OnInit, OnDestroy {
  selectedLanguageSubscription: Subscription;
  headerTitle = '';
  origin = '';
  navigationParams: any;

  constructor(private navigationParamsService: NavigationParamsService, public router: Router,
    private appStateService: AppStateService, private translate: TranslateService, readonly activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.selectedLanguageSubscription = this.appStateService.subscribeSelectedLanguage().subscribe((language: string) => {
      this.translate.setDefaultLang(language);
      this.translate.use(language);
    });


    this.activeRoute.queryParams.subscribe(params => {
      this.navigationParamsService.registerNavigationParams();
      this.navigationParams = this.navigationParamsService.getNavigationParam();
      this.headerTitle = this.navigationParams?.title;
      this.origin = this.navigationParams?.origin;

      switch (this.origin) {
        case PageOrigins.monthlyFinancialIndicatorDetails:
          this.router.navigate(['', { outlets: { overlayOutlet: null } }]).then(() => {
            this.router.navigate([RouteLinkConstants.monthlyFinancialIndicatorDetails],
              { queryParams: this.navigationParams });
          });
          break;
      }
    });   
  }

  ngOnDestroy(): void {
    if (this.selectedLanguageSubscription) { this.selectedLanguageSubscription.unsubscribe(); }
  }
}
