export class RegistrationUser {
    id: string;
    createdOn: Date;
    updatedOn?: Date;
    createdBy?: string;
    createdByName: string;
    updatedBy: string;
    email: string;
    primaryPhoneNumber: string;
    profileImageUrl: string;
    uploadedImage: string;
    firstName: string;
    lastName: string;
    ssn: string;
    b2CUserId: string;
    isArchived = false;
    userIdentifier?: string;
    capassaRoles: CapassaRole[];
    userRoles: UserRole[];
    actions: Permission[];
    portfolioId: string;
}

export class B2CUser {
    id: string;
    registrationUserId?: string;
    userIdentifier?: string;
    name?: string;
    firstName?: string;
    lastName?: string;
    dateOfBirth?: Date;
    aud?: string;
    authTime?: number;
    email: string;
    exp?: number;
    iat?: number;
    idp: string;
    iss: string;
    nbf?: number;
    nonce?: string;
    oid?: string;
    sub?: string;
    tfp: string;
    ver: string;
    createdOn: Date;
    updatedOn?: Date;
    createdBy?: string;
    updatedBy?: string;
    isArchived = false;
}

export class Permission {
    id: string;
    role: string;
    action: string;
    roleId: string;
    actionId: number;
    actionCategoryId: string;
    actionCategory: Date;
    createdBy: string;
    actionDefinition: string;
}

export class IntegrationSystem {
    id: string;
    name: string;
    apiurl: string;
    accessTokenUrl: string;
    authenticationType: string;
    lLogo: string;
    isEnable?: boolean;
    tType: string;
    clientId?: string;
    systemId: string;
    displayName: string;
    lastSyncDate?: Date;
}

export class AccountUserActions {
    userId: string;
    roleId: string;
    accountId: string;
    subscriptionId: string;
    userActionIds: number[];
    subscriptionActionIds: number[];
}

export class CapassaRole {
    id: string;
    languageId: string;
    capassaRole: string;
    roleDefinition: string;
    isEnabled: boolean;
    isArchived: boolean;
}

export class AccountUserSystemRole {
    userId: string;
    roleId: string;
    role: string;
    accountId: string;
}

export class UserLoginHistory {
    id: string;
    registrationUserId: string;
    date: Date;
    ClientAppId: number;
}

export class UserRole {
    roleId: number;
    userId: string;
    updatedOn: Date;
    updatedBy: string;
    createdOn: Date;
    createdBy: string;
    role: Role;
}

export class Role {
    roleId: number;
    name: string;
    nameNorwegian: string;
    roleCode: string;
}
