
export enum Device {
    DesktopMac = 1,
    DesktopWindows = 2,
    IOS = 3,
    Android = 4
}

export enum RoleCode {
    Chairperson = 'LEDE',
    CEO = 'DAGL'
}

export enum DefaultSignUpSignInMethod {
    EmailSignUpSignIn = 'EmailSignUpSignIn',
    PhoneSignUpSignIn = 'PhoneSignUpSignIn'
}

export enum UserFlow {
    Invitation = 'Invitation',
    MobileSignUp = 'MobileSignUp',
    SignUp = 'SignUp',
    SignIn = 'SignIn',
    MobileSignIn = 'MobileSignIn',
    AddCompany = 'AddCompany',
    Dashboard = 'Dashboard',
    AddAccountingSystem = 'AddAccountingSystem',
    NewTermAndCondition = 'NewTermAndCondition',
    ManageIntegration = 'ManageIntegration',
    DashboardIntegration = 'DashboardIntegration',
    ReportCenterIntegration = 'ReportCenterIntegration',
    HomeIntegration = 'HomeIntegration',
    KeyFinancialDetailsIntegration = 'KeyFinancialDetailsIntegration',
    EmailSignIn = "EmailSignIn",
    EmailSignUp = "EmailSignUp",
    ReportIntegration = 'ReportIntegration',
    PredictionIntegration = 'PredictionIntegration',
    KeyFinancialHomeIntegration = 'KeyFinancialHomeIntegration',
    ProfitAndLossIntegration = 'ProfitAndLossIntegration',
    BalanceSheetIntegration = 'BalanceSheetIntegration',
    BudgetIntegration = 'BudgetIntegration',
    ChangeBudgetIntegration = 'ChangeBudgetIntegration',
    BudgetComparisonIntegration = 'BudgetComparisonIntegration',
    LiquidityIntegration = 'LiquidityIntegration',
}

export enum DeepLinkingAction {
    CloseCustomerWebPortal = 'CloseCustomerWebPortal'
}


export enum LoadingBarStatus {
    Hide = 'hide',
    ShowProgressBar = 'showProgressBar',
    ShowWithoutWording = 'showWithoutWording',
    Default = 'default'
}

export enum OnboardingContainerState {
    None = -1,
    OnboardCompany = 0,
    CompanySelection = 2,
    AccountingSystemSelection = 5,
    AccountingTermConditions = 6,
    PowerOfficeIntegration = 7,
    TripleTexIntegration = 8,
    AccountingAPIError = 9,
    IntegrationSuccess = 10,
    SSNAlreadyUsed = 12,
    TwentyFourSevenOfficeIntegration = 13,
    Welcome = 14,
    InvitationSuccess = 15,
    DuettIntegration = 16,
    FikenIntegration = 17,
}

export enum AccountingSystemEnum {
    PowerOffice = 'PowerOffice',
    TripleTex = 'Tripletex',
    Visma = 'VISMA',
    Fiken = 'Fiken',
    TwentyFourSevenOffice = 'TwentyFourSevenOffice',
    Duett = 'Duett'
}

export enum ModalTemplateType {
    Success = 1,
    Error = 2,
    InvitationError = 3
}

export enum AgreementTypes {
    None = -1,
    CapassaAgreementMobile = 1,
    BankSystemAgreementMobile = 2,
    AccountingSystemAgreementMobile = 3,
    CapassaAgreementWebPortal = 4,
    BankSystemAgreementWebPortal = 5,
    AccountingSystemAgreementWebPortal = 6,
    CapassaAgreementPortfolioPortal = 7,
}

export enum UserAction {
    AddAccountingIntegration = 1005,
    DeleteAccountingIntegration = 5003,
    ModifyUserRole = 1012,
    DeleteUser = 1009,
    ViewAllUsers = 1011,
    ModifyUserProfile = 1004,
    ViewInvitations = 1008,
    CreateInvitation = 1001,
    ViewUser = 1010,
    DeleteCompany = 1003,
    ModifyCompanyInfo = 1006,
    ViewCompany = 1007,
    ViewCapassaIndex = 2001,
    ViewLiquidityGraph = 5012,
    ViewMonthlyCurrentRatioGraph = 5021,
    ViewMYearlyCurrentRatioGraph = 5022,
    ViewYearlyEquityRatioGraph = 5024,
    ViewFinancialChallenges = 5030,
    ViewMonthlyProfitMarginsGraph = 5010,
    ViewMonthlyBalanceSheetGraph = 5019,
    ViewPayableReceivableGraph = 5011,
    ViewGrossProfitGraph = 5009,
    ViewMonthlyEquityRatioGraph = 5023,
    ViewLiquidityRatioGraph = 5014,
    ViewMonthlyProfitLossGraph = 2003,
    ViewYearlyProfitLossGraph = 5015,
    ViewMonthlyReport = 3004,
    ViewYearlyWorkingCapitalGraph = 5018,
    ViewYearlyProfitMarginsGraph = 5016,
    ViewBurnRateGraph = 5013,
    ViewYearlyBalanceSheetGraph = 5017,
    ViewAccumulatedProfitGraph = 5029,
    ViewMonthlyWorkingCapitalGraph = 5020,
    ViewKeyFinancial = 5031,

    ViewTriggerBasedAdviceCard = 3014,
    ViewAdvanceTaxNoAccountingSystem = 5025,
    ViewAdvanceTaxCard = 5026,
    ViewZeroEquityWarningCard = 3012,
    ViewEquityWarningCard = 3011,
    ViewPensionCard = 3010,
    ViewNoAccountingSystemTaxCard = 3013,
    ViewWithholdingTaxCard = 3008,
    ViewCompensationTaxCard = 3007,
    ViewNoAccountingSystemCard = 3009,
    ViewWelcomeMessageCard = 3005,
    ViewAdviceCard = 3003,
    ViewNotificationCard = 3002,
    ViewAndExportConsolidatedFinancials = 5034,
    ViewMonthlyReportDrillDown = 5035,
    InviteAnotherCompany = 5036,
    ViewPredictions = 5037,
    ViewMarketplace = 5038,
    RegisterMarketplaceProducts = 5039,
    viewProfitLoss = 5041,
    viewBalanceSheet = 5042,
    ViewPLBudget = 5043,
    CreatePLBudget = 5044,
    ChangePLBudget = 5045,
    DeletePLBudget = 5046,
    ExportPLBudget = 5047,
    ViewBudgetComparison = 5048,
    ViewNoBudgetingCard = 5049,
    ViewLiquidityBudget = 5050,
    ChangeLiquidityBudget = 5051,
    ExportLiquidityBudget = 5053,
    ViewDataRoom = 5054,
    CreateDataRoom = 5055,
    ChangeDataRoom = 5056,
    DeleteDataRoom = 5057,
    UploadFilesDataRoom = 5058,
    RenameFilesDataRoom = 5059,
    DownloadFilesDataRoom = 5060,
    DeleteFilesDataRoom = 5061,
    ViewOverviewDashboard  = 5062,
    ViewProfitabilityDashboard = 5063,
    ViewBalanceSheetDashboard = 5064,
    ViewLiquidityDashboard = 5065,
    CreateLiquidityBudget = 5066,
    DeleteLiquidityBudget = 5067,
    ChangeSubscriptions = 5070,
    ViewSubscriptions = 5069,
    ViewLiquidityReport = 5068
}

export enum TabComponent {
    AllId = '17146751-606A-45E8-85DB-CB322E3FD777',
}

export enum InvitationStatus {
    Expired = 1,
    NotFound = 2,
    Pending = 3
}

export enum ClientApp {
    Web = 1,
    Mobile = 2
}

export enum UserPreferenceType {
    KeyFinancial = 1,
    DashboardChart = 5,
    InvestorMatrixColumnOptions = 7,
    QuarterReportKeyFinancial = 8,
    EmailDistributionList = 11,
    YearlyReportKeyFinancial = 13,
    Sidebar = 14
}
export enum KeyFinancialConfig {
    MonthlyReport = 'addToMonthlyReport',
    ReportingCenter = 'addToReportingCenter'
}

export enum FinancialIndicatorType {
    None = -1,
    Revenue = 1,
    Expense = 2,
    Result = 3,
    GrossProfitMargin = 4,
    OperatingProfitMargin = 5,
    NetProfitMargin = 6,
    EquityRatio = 7,
    QuickRatio = 8,
    WorkingCapital = 9,
    Equity = 10,
    Liability = 11,
    Assets = 12,
    FreeCashFlow = 13,
    Ebitda = 14,
    Nibd = 15,
    NibdEbitda = 16,
    UndistributedProfitLoss = 17,
    EbitdaMargin = 18,
    Ebit = 19,
    EbitMargin = 20,
    GrossProfit = 21,
    BurnRate = 22,
    CashRunway = 23,
    CashAndCashEquivalents = 24,
    CostOfSales = 25,
    LabourCost = 26,
    Depreciation = 27,
    OtherOperatingExpenses = 28,
    NetFinancialItems = 29,
    RAndDInvestments = 30,
    OtherCapex = 31,
    CurrentAssetsWithoutCash = 32,
    FixedAssets = 33,
    OtherDebt = 34,
    InterestBearingDebt = 35,
    DebtAndEquity = 36,
    NibdCash = 37,
    NoOfEmployees = 38,
    ROE = 39,
    ROCE = 40,
    CurrentRatio = 41,
    InterestCoverageRatio = 42,
    DebtServiceCoverageRatio = 43,
    ROA = 44
}

export enum FinancialKeyDetails {
    Revenue = 0,
    CostOfSales = 1,
    LabourCost = 2,
    OtherOperatingCost = 3
}

export enum KeyFinancialGroup {
    ProfitLoss = 0,
    BalanceSheet = 1,
    LiquidityRisk = 2,
    Other = 3
}

export enum BalanceSheetGroup {
    Assets = 0,
    FixedAssets = 1,
    IntangibleFixedAssets = 2,
    TangibleFixedAssets = 3,
    FinancialFixedAssets = 4,
    CurrentAssets = 5,
    Receivables = 6,
    OtherCurrentAssets = 7,
    BankDeposits = 8,
    EquitiesAndLiabilities = 9,
    Equity = 10,
    PaidInCapital = 11,
    RetainedEarnings = 12,
    Liabilities = 13,
    CurrentLiabilities = 14,
    LongTermLiabilities = 15,
}

export enum BudgetFiguresType {
    Actual = 0,
    Thousand = 1,
    Million = 2
}

export enum NotificationSubType {
    Notification = 1,
    Advice = 2,
    MonthlyReport = 3,
    WelcomeCard = 4,
    Warning = 5,
    CompensationTax = 6,
    WithHoldingTax = 7,
    NoAccountingSystem = 8,
    Otp = 9,
    EquityWarning = 10,
    EquityReachingZero = 11,
    CompensationWithHoldingNoAccountSys = 12,
    TriggerBasedAdvice = 13,
    AdvanceTaxFirstInstallmentNoAccountSys = 14,
    AdvanceTaxSecondInstallmentNoAccountSys = 15,
    AdvanceTaxFirstInstallment = 16,
    AdvanceTaxSecondInstallment = 17,
    HolidayPayNoAccountSys = 18,
    HolidayPay = 19,
    NoBudgeting = 21,
}

export enum LiquidityGroup {
    AdjustmentsForNoncashItems = 0,
    ChangesInWorkingCapital = 1,
    PurchaseAndSaleOfAssets = 2,
    PurchaseAndSaleOfInvestments = 3,
    IssuanceAndRepaymentOfDebt = 4,
    IssuanceAndRepurchaseOfEquity = 5
}
