import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { UserDataService } from 'src/app/modules/core/user-service/user-data.service';
import { UserLoginHistory } from 'src/app/modules/core/user-service/user.model';
import { ClientApp } from 'src/app/modules/shared/globals';
import { Account } from 'src/app/modules/shared/models/account';
import { AccountDataService } from 'src/app/modules/shared/services/account-user-data.service';
import { CompanyDataService } from 'src/app/modules/shared/services/company-data.service';
import { SharedStorageService } from 'src/app/modules/shared/services/shared-storage.service';
import { UserPermissionService } from 'src/app/modules/shared/services/user-permission.service';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { AuthService } from 'src/app/modules/core/auth/auth.service';
import { RouteLinkConstants } from 'src/app/app.constants';

@Injectable({
  providedIn: 'root',
})

export class UserService {

  constructor(private userDataService: UserDataService, private companyDataService: CompanyDataService,
    private sharedStorageService: SharedStorageService, private userPermissionService: UserPermissionService,
    private appStateService: AppStateService, private accountDataService: AccountDataService, private authService: AuthService,
    private router: Router) {
  }

  saveUserLoggedInTime(userId: string): void {
    const userLoginHistory = new UserLoginHistory();
    userLoginHistory.registrationUserId = userId;
    userLoginHistory.date = new Date();
    userLoginHistory.ClientAppId = ClientApp.Web;
    this.userDataService.postUserLoggedInTime(userLoginHistory).subscribe(() => {
    });
  }

  updateB2CUserRegistrationUserId(registrationUserId: string): void {
    this.sharedStorageService.addB2CUserRegistrationId(registrationUserId);
  }

  getB2CUserRegistrationId(): string {
    return this.sharedStorageService.getB2CUserRegistrationId();
  }

  updateB2CUserId(b2cUserId: string): void {
    this.sharedStorageService.addB2CUserId(b2cUserId);
  }

  getB2CUserId(): string {
    return this.sharedStorageService.getB2CUserId();
  }

  setUpCompanyAccountForUser(registrationUserId: string): Observable<Account[] | undefined> {
    const userAccount = this.authService.getAccount();
    const b2CUserId = this.getB2CUserId() === 'null' ? userAccount?.localAccountId : this.getB2CUserId();

    if (!b2CUserId) {
      return new Observable<undefined>();
    }

    return registrationUserId === 'null' ? this.userDataService.getRegistrationB2cUserById(b2CUserId).pipe(switchMap((b2cUser: any) => {
      if (!b2cUser.registrationUserId) {
        this.router.navigate(['', { outlets: { overlayOutlet: null } }]).then(() => {
          this.router.navigate([RouteLinkConstants.landingPage]);
        });
      }
      return this.companyDataService.getUserOnBoardedAccounts(b2cUser.registrationUserId).pipe(
        map((onBoardedAccounts: Account[]) => {
          if (onBoardedAccounts.length === 0 || onBoardedAccounts[0] == null) {
            return;
          }
          this.sharedStorageService.addAccountIds(onBoardedAccounts.map(a => a.id));
          const sortOnBoardedAccounts = onBoardedAccounts.sort((a, b) => a.name > b.name ? 1 : -1);

          // Clear action history, set default company and publish onBoarded accounts
          this.userPermissionService.clearActionHistory(sortOnBoardedAccounts.map(a => a.id));
          const defaultCompany = onBoardedAccounts.find(c => c.isDefault);
          const selectedAccount = defaultCompany ? defaultCompany : sortOnBoardedAccounts[0];
          this.appStateService.publishOnBoardCompanyAccounts(sortOnBoardedAccounts);
          this.accountDataService.updateSelectedAccount(selectedAccount);
          this.userPermissionService.loadAccountUserActions(selectedAccount.id);
          //This will indicate that all pre conditions has been met to load home
          this.appStateService.publishCanLoadHome(true);
          return sortOnBoardedAccounts;
        }))
    })) : this.companyDataService.getUserOnBoardedAccounts(registrationUserId).pipe(
      map((onBoardedAccounts: Account[]) => {
        if (onBoardedAccounts.length === 0 || onBoardedAccounts[0] == null) {
          return;
        }
        this.sharedStorageService.addAccountIds(onBoardedAccounts.map(a => a.id));
        const sortOnBoardedAccounts = onBoardedAccounts.sort((a, b) => a.name > b.name ? 1 : -1);

        // Clear action history, set default company and publish onBoarded accounts
        this.userPermissionService.clearActionHistory(sortOnBoardedAccounts.map(a => a.id));
        const defaultCompany = onBoardedAccounts.find(c => c.isDefault);
        const selectedAccount = defaultCompany ? defaultCompany : sortOnBoardedAccounts[0];
        this.appStateService.publishOnBoardCompanyAccounts(sortOnBoardedAccounts);
        this.accountDataService.updateSelectedAccount(selectedAccount);
        this.userPermissionService.loadAccountUserActions(selectedAccount.id);
        //This will indicate that all pre conditions has been met to load home
        this.appStateService.publishCanLoadHome(true);
        return sortOnBoardedAccounts;
      }));
  }

  updateInitialB2cRedirection(initialB2cRedirectionStatus: string): void {
    const redirection = this.sharedStorageService.getInitialB2cRedirection();
    if (!redirection || redirection == 'null' || initialB2cRedirectionStatus == 'false') {
      this.sharedStorageService.addInitialB2cRedirection(initialB2cRedirectionStatus);
    }
  }

  getInitialB2cRedirection(): boolean {
    return (this.sharedStorageService.getInitialB2cRedirection() === 'true');
  }
}
