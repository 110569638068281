<app-profile-image-edit [location]='profileImageLocation' [isMenuCollapsed]='isMenuCollapsed' (menuCollapsed)="onProfileImageEdit($event)"></app-profile-image-edit>
<div class="organisations-block" *ngIf="showAccounts  && onBoardedAccounts && onBoardedAccounts.length > 0">
    <!-- <div class="toggle-company-type" *ngIf="portfolioId !== null">
        <div class="btn-company-type" [ngClass]="{'selected' : selectedCompanyType === 'general'}"
            (click)="toggleCompanyType('general')">{{'General'| translate }}</div>
        <div class="btn-company-type" [ngClass]="{'selected': selectedCompanyType === 'portfolio'}"
            (click)="toggleCompanyType('portfolio')">{{'Portfolio'| translate }}</div>
    </div> -->
    <div class="lbl-title">
        {{'Companies'| translate }}
        <span>. {{onBoardedAccounts.length | number:'2.0'}}</span>
    </div>
    <div *ngIf="selectedAccount" class="lang-selection-wrapper"
        [ngClass]="{'disabled-company-warning-icon': !selectedAccount.isActive}">
        <select class="lang-selection" [(ngModel)]="selectedAccount" (ngModelChange)="setSelectedCompany(true)">
            <option *ngFor="let account of onBoardedAccounts" [ngValue]="account">
                {{account.name}} {{account.portfolioId? ' [P]':''}}
            </option>
        </select>
    </div>
    <div>
        <a class="btn-add-company" (click)="onBoardCompany()">
            {{'Add a company'| translate}}
        </a>
        <a *ngIf="canInviteAnotherCompany" class="btn-invite-another-company" (click)="onInviteAnotherCompany()">
            {{'Invite another company'| translate}}
        </a>
    </div>
</div>

<div class="app-menu">
    <div class="left-menu-toggle-wrapper">
        <h3 class="h3">{{'Menu'| translate }}</h3>
        <div class="left-menu-toggle" (click)="toggleMenu()"></div>
    </div>
    <ul class="menu-list">
        <li class="menu-item link-home" [ngClass]="{'selected': selectedMenuItem === constants.home}"
            (click)="goToLink(constants.home)">{{'Home'| translate }}</li>
        <li class="menu-item link-analytics"
            *ngIf="canViewDashboard || canViewKeyFinancial || canViewPredictions || canViewInvestorMatrix"
            [ngClass]="{'selected': selectedMenuItem === constants.analytics, 'sub-menu-opend': showAnalytics}"
            (click)="selectMenu(constants.analytics)">{{'Analytics'| translate }}</li>

        <ul class="menu-list-sub" *ngIf="showAnalytics">
            <li *ngIf="canViewDashboard" class="menu-item-sub"
                [ngClass]="{'selected': selectedSubMenuItem === constants.dashboard}"
                (click)="goToLink(constants.dashboard)">
                {{'Dashboard'|
                translate }}</li>
            <li *ngIf="canViewKeyFinancial" class="menu-item-sub"
                [ngClass]="{'selected': selectedSubMenuItem === constants.keyFinancial}"
                (click)="goToLink(constants.keyFinancial)">
                {{'Key Financial'| translate }}</li>
            <li *ngIf="canViewPredictions" class="menu-item-sub"
                [ngClass]="{'selected': selectedSubMenuItem === constants.prediction}"
                (click)="goToLink(constants.prediction)">
                {{'Predictions'| translate }}</li>
            <li *ngIf="canViewInvestorMatrix" class="menu-item-sub"
                [ngClass]="{'selected': selectedSubMenuItem === constants.investorMatrix}"
                (click)="goToLink(constants.investorMatrix)">{{'Investor Matrix'| translate
                }}
            </li>
        </ul>
        <li class="menu-item link-reports" *ngIf="canViewBalanceSheet || canViewProfitAndLoss || canViewLiquidityReport"
            [ngClass]="{'selected': selectedMenuItem === constants.report, 'sub-menu-opend': showReport}"
            (click)="selectMenu(constants.report)">{{'Reports'| translate }}</li>
        <ul class="menu-list-sub" *ngIf="showReport">
            <li class="menu-item-sub" [ngClass]="{'selected': selectedSubMenuItem === constants.profitAndLoss}"
                *ngIf="canViewProfitAndLoss" (click)="goToLink(constants.profitAndLoss)">{{'Profit and loss'|
                translate }}</li>
            <li class="menu-item-sub" [ngClass]="{'selected': selectedSubMenuItem === constants.balanceSheet}"
            *ngIf="canViewBalanceSheet" (click)="goToLink(constants.balanceSheet)">{{'Balance sheet'| translate }}
            </li>
            <li class="menu-item-sub" [ngClass]="{'selected': selectedSubMenuItem === constants.liquidityReport}"
            *ngIf="canViewLiquidityReport" (click)="goToLink(constants.liquidityReport)">{{'Liquidity'| translate }}
            </li>
        </ul>
        <li class="menu-item link-budget" *ngIf="canViewPLBudget || canViewLiquidityBudget || canViewBudgetComparison"
            [ngClass]="{'selected': selectedMenuItem === constants.budget, 'sub-menu-opend': showBudget}"
            (click)="selectMenu(constants.budget)">{{'Budget'| translate }}</li>
        <ul class="menu-list-sub" *ngIf="showBudget">
            <li class="menu-item-sub" *ngIf="canViewPLBudget || canViewLiquidityBudget"
                [ngClass]="{'selected': selectedSubMenuItem === constants.budgeting}"
                (click)="goToLink(constants.budgeting)">{{'Budgeting'| translate }}
            </li>
            <li class="menu-item-sub" [ngClass]="{'selected': selectedSubMenuItem === constants.budgetComparison}"
                (click)="goToLink(constants.budgetComparison)" *ngIf="canViewBudgetComparison">{{'Actual vs budget'|
                translate }}
            </li>
        </ul>

        <li class="menu-item link-data-room" [ngClass]="{'selected': selectedMenuItem === constants.dataRoom}"
            *ngIf="canViewDataRoom" (click)="goToLink(constants.dataRoom)">{{'Data rooms'| translate }}</li>

        <li class="menu-item link-manage-team" *ngIf="canViewUserTeams || canViewInvitations"
            [ngClass]="{'selected': selectedMenuItem === constants.manageTeam, 'sub-menu-opend': showManageTeam}"
            (click)="selectMenu(constants.manageTeam)">{{'Manage team'| translate }}</li>

        <ul class="menu-list-sub" *ngIf="showManageTeam">
            <li *ngIf="canViewUserTeams" class="menu-item-sub"
                [ngClass]="{'selected': selectedSubMenuItem === constants.myTeam}" (click)="goToLink(constants.myTeam)">
                {{'My team'|
                translate }}</li>
            <li *ngIf="canViewInvitations" class="menu-item-sub"
                [ngClass]="{'selected': selectedSubMenuItem === constants.invitation}"
                (click)="goToLink(constants.invitation)">
                {{'Invitations'| translate }}</li>
        </ul>
        <li *ngIf="canViewIntegrations" class="menu-item manage-integration"
            [ngClass]="{'selected': selectedMenuItem === constants.manageIntegration}"
            (click)="goToLink(constants.manageIntegration)">{{'Integrations'| translate
            }}
        </li>

        <!-- <li class="menu-item-sub">{{'Billing and Plans'| translate }}</li> -->
        <!-- <li class="menu-item link-manage-integrations">{{'Manage Integrations'| translate }}</li> -->
        <li class="menu-item link-settings" *ngIf="canViewUserDetails || canViewCompanyDetails || canViewSubscriptions"
            [ngClass]="{'selected': selectedMenuItem === constants.settings, 'sub-menu-opend': showSettings}"
            (click)="selectMenu(constants.settings)">{{'Settings'| translate }}</li>
        <ul class="menu-list-sub" *ngIf="showSettings">
            <!-- <li class="menu-item-sub">{{'Billing and Plans'| translate }}</li> -->
            <li class="menu-item-sub" [ngClass]="{'selected': selectedSubMenuItem === constants.myProfile}"
                *ngIf="canViewUserDetails" (click)="goToLink(constants.myProfile)">{{'My profile'|
                translate }}</li>
            <li class="menu-item-sub" [ngClass]="{'selected': selectedSubMenuItem === constants.companyProfile}"
                *ngIf="canViewCompanyDetails" (click)="goToLink(constants.companyProfile)">{{'Company'| translate }}
            </li>
            <li class="menu-item-sub" [ngClass]="{'selected': selectedSubMenuItem === constants.subscription}"
            *ngIf="canViewSubscriptions" (click)="goToLink(constants.subscription)">{{'Subscriptions'| translate }}
        </li>
        </ul>
    </ul>
</div>

<div class="app-menu left-menu-footer d-flex flex-column">
    <div class="app-menu-links">
        <a class="app-menu-link" (click)="goToLink(faqPage)">{{'Help center'| translate }}</a>
        <a class="app-menu-link" [ngClass]="{'selected': selectedMenuItem === constants.contactUs}"
            (click)="goToLink(constants.contactUs)">{{'Contact us'|
            translate }}</a>
        <a class="app-menu-link" (click)="goToLink(constants.privacyPolicyPage)">{{'Privacy policy'| translate }}</a>

    </div>
    <span class="app-copyright">Copyright © 2021 Capassa AS.</span>
</div>
