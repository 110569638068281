import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { UserDataService } from 'src/app/modules/core/user-service/user-data.service';
import { UserInterfaceService } from 'src/app/modules/shared/services/user-interface.service';
import { LoadingBarStatus, UserAction } from 'src/app/modules/shared/globals';
import { Account } from 'src/app/modules/shared/models/account';
import { CompanyDataService } from 'src/app/modules/shared/services/company-data.service';
import { AccountDataService } from 'src/app/modules/shared/services/account-user-data.service';
import { B2CUser } from 'src/app/modules/core/user-service/user.model';

@Component({
  selector: 'app-company-image-edit',
  templateUrl: './company-image-edit.component.html',
  styleUrls: ['./company-image-edit.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class CompanyImageEditComponent implements OnInit, OnDestroy {

  companyName = '';
  companyAlias = '';
  companyImageUrl = '';
  companyImageUrlSubscription: Subscription;
  loggedInB2CUserSubscription: Subscription;
  userActionSubscription: Subscription;
  showProfileImageOptions = false;
  chooseImageOption = false;
  isReadonly = true;
  onboardedAccounts: Account[];
  account: Account;
  registrationB2cUser: B2CUser;

  @ViewChild('imageInput') photoInput: ElementRef;

  constructor(private appStateService: AppStateService, private userService: UserDataService,
              private uiService: UserInterfaceService, private eref: ElementRef,
              private companyDataService: CompanyDataService, private translate: TranslateService,
              private accountDataService: AccountDataService) { }

  ngOnInit(): void {
    this.loggedInB2CUserSubscription = this.appStateService.subscribeLoggedInB2CUser().subscribe((b2cUser: B2CUser) => {
      this.registrationB2cUser = b2cUser;
      this.account = this.accountDataService.getSelectedAccount();
      this.setDisplayName(this.account?.name ?? '');
      if (this.registrationB2cUser && this.registrationB2cUser.registrationUserId) {
        this.companyDataService.getUserOnBoardedAccounts(this.registrationB2cUser.registrationUserId).subscribe((onBoardedAccounts: Account[]) => {
          const company: Account | undefined = onBoardedAccounts && onBoardedAccounts.length ? onBoardedAccounts.find(c => c.id === this.account.id) : undefined;
          this.account = company ? company : this.account;
          this.companyImageUrl = company?.logo ?? '';
          this.appStateService.publishCompanyImage(this.companyImageUrl);
        });
      }
    });

    this.companyImageUrlSubscription = this.appStateService.subscribeCompanyImage().subscribe((imageUrl: string) => {
      this.companyImageUrl = imageUrl;
      this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
    });

    this.userActionSubscription = this.appStateService.subscribeUserRoleActions().subscribe(actions => {
      this.isReadonly = actions.find(action => action === UserAction.ModifyCompanyInfo) ? false : true;
    });
  }

  ngOnDestroy(): void {
    if (this.companyImageUrlSubscription) { this.companyImageUrlSubscription.unsubscribe(); }
    if (this.loggedInB2CUserSubscription) { this.loggedInB2CUserSubscription.unsubscribe(); }
    if (this.userActionSubscription) { this.userActionSubscription.unsubscribe(); }
  }

  setDisplayName(companyName: string): void {
    if (companyName) {
      this.companyName = companyName;
      this.companyAlias = companyName.substring(0, 1).toUpperCase();
    }
  }

  hideLeftMenu(): void {
    this.appStateService.publishMenuState(false);
  }

  uploadCompanyImage(imageInput: any): void {
    if (imageInput.files && imageInput.files[0]) {
      const profileImage = imageInput.files[0];
      if (profileImage.type.indexOf('image') === -1) {
        this.uiService.showCommonErrorMessage();
        this.photoInput.nativeElement.value = '';
      } else {
        this.appStateService.publishLoadingBarStatus(LoadingBarStatus.ShowProgressBar);
        const reader = new FileReader();
        reader.addEventListener('load', (event: any) => {
          const imageData = event.target.result;
          this.account.image = btoa(imageData);
          if (this.account.accountUserRoles === null) {
            this.account.accountUserRoles = [];
          }
          this.account.logo = this.companyImageUrl;
          this.companyDataService.updateAccountData(this.account).subscribe((acc: Account) => {
              this.companyImageUrl = acc.logo;
              this.appStateService.publishCompanyImage(this.companyImageUrl);
              this.chooseImageOption = false;
            },
            error => {
              this.uiService.showCommonErrorMessage();
              this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
            });
        });
        reader.addEventListener('error', () => {
          this.uiService.showCommonErrorMessage();
          this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
        });
        reader.readAsBinaryString(profileImage);
      }
    }
  }

  showUserProfileOptions(event: any): void {
    if (this.isReadonly) {
      this.chooseImageOption = false;
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (this.chooseImageOption) {
      this.chooseImageOption = false;
      return;
    }

    if (this.companyImageUrl) {
      this.showProfileImageOptions = true;
      event.preventDefault();
      event.stopPropagation();
    }
  }

  hideUserProfileOptions(): void {
    this.showProfileImageOptions = false;
  }

  deleteImage(): void {
    this.appStateService.publishLoadingBarStatus(LoadingBarStatus.ShowProgressBar);
    this.companyDataService.deleteCompanyImage(this.account.id).subscribe(() => {
        this.companyImageUrl = '';
        this.appStateService.publishCompanyImage(this.companyImageUrl);
        this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
      },
      error => {
        this.uiService.showCommonErrorMessage();
        this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
      });
  }

  chooseImage(event: any): void {
    this.chooseImageOption = true;
    event.returnValue = true;
    this.photoInput.nativeElement.click();
  }

  onClick = (event: any): void => {
    this.showProfileImageOptions = false;
    this.chooseImageOption = false;
  }
}
