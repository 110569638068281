import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

import { B2CUser, RegistrationUser } from 'src/app/modules/core/user-service/user.model';
import { Account } from 'src/app/modules/shared/models/account';
import { BrowserState } from 'src/app/modules/shared/models/browser-state';
import { ErrorResult } from 'src/app/modules/shared/models/error';
import { UserPreference } from 'src/app/modules/shared/models/UserPreference';
import { AccountingSystemIntegration } from 'src/app/modules/shared/models/accounting-system-status';
import { AccountIntegrationStatus } from 'src/app/modules/shared/models/account-integration-status';
import { Currency } from 'src/app/modules/shared/models/currency';
import { DefaultCurrency } from 'src/app/app.constants';
import { AccountSubscriptionHistory } from 'src/app/modules/shared/models/account-subscription-history';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  private loggedInB2cUser = new BehaviorSubject<B2CUser>(new B2CUser());
  private registrationUser = new BehaviorSubject<RegistrationUser>(new RegistrationUser());
  private activeCompanyAccount = new Subject<Account>();
  private selectedLanguage = new BehaviorSubject<string>('');
  private onBoardedAccount = new ReplaySubject<Account>();
  private showLoadingBar = new BehaviorSubject<string>('');
  private profileImage = new ReplaySubject<string>();
  private showResponsiveMenu = new ReplaySubject<boolean>();
  private errorResult = new BehaviorSubject<ErrorResult>(new ErrorResult());
  private browserState = new ReplaySubject<BrowserState>();
  private userActions = new ReplaySubject<number[]>();
  private subscriptionActions = new ReplaySubject<number[]>();
  private onboardCompanyAccounts = new BehaviorSubject<Account[]>([]);
  private showOverlay = new ReplaySubject<boolean>();
  private accountSystemIntegrationStatus = new ReplaySubject<boolean>(1);
  private accountSystemReConnectionStatus = new BehaviorSubject<boolean>(false);
  private accountSystemIntegration = new BehaviorSubject<AccountingSystemIntegration>(new AccountingSystemIntegration());
  private closeErrorPopUp = new BehaviorSubject<string>('');
  private userPreferences = new BehaviorSubject<UserPreference[]>([]);
  private showFullscreen = new BehaviorSubject<boolean>(false);
  private reloadMainMenu = new BehaviorSubject<boolean>(false);
  private accountIntegrationStatus = new BehaviorSubject<AccountIntegrationStatus>(new AccountIntegrationStatus());
  private companyImage = new ReplaySubject<string>();
  private searchText = new BehaviorSubject<string>('');
  private switchedAccount = new Subject<Account>();
  private selectedCurrency = new BehaviorSubject<Currency>(DefaultCurrency);
  private canLoadHome = new ReplaySubject<boolean>();
  private currentSelectedMonthStatus: boolean | undefined = undefined;
  private selectedMonthStatus = new Subject<boolean>();
  private menuToggleEvent= new Subject<boolean>();
  private activeSubscription = new BehaviorSubject<AccountSubscriptionHistory>(new AccountSubscriptionHistory());

  constructor() { }

  publishUserPreferences(userPreferences: UserPreference[]): void {
    this.userPreferences.next(userPreferences);
  }

  subscribeUserPreferences(): Observable<UserPreference[]> {
    return this.userPreferences.asObservable();
  }

  publishBrowserState(state: BrowserState): void {
    this.browserState.next(state);
  }

  subscribeBrowserState(): Observable<BrowserState> {
    return this.browserState.asObservable();
  }

  publishLoggedInB2CUser(user: B2CUser): void {
    this.loggedInB2cUser.next(user);
  }

  subscribeLoggedInB2CUser(): Observable<B2CUser> {
    return this.loggedInB2cUser.asObservable();
  }

  publishSelectedLanguage(language: string): void {
    this.selectedLanguage.next(language);
  }

  subscribeSelectedLanguage(): Observable<string> {
    return this.selectedLanguage.asObservable();
  }

  getSelectedLanguage(): string {
    return this.selectedLanguage.value;
  }

  publishRegistrationUser(user: RegistrationUser): void {
    this.registrationUser.next(user);
  }

  subscribeRegistrationUser(): Observable<RegistrationUser> {
    return this.registrationUser.asObservable();
  }

  publishOnBoardedUserAccount(onboardedAccount: Account): void {
    this.onBoardedAccount.next(onboardedAccount);
  }

  subscribeOnBoardedUserAccount(): Observable<Account> {
    return this.onBoardedAccount.asObservable();
  }

  publishLoadingBarStatus(show: string): void {
    this.showLoadingBar.next(show);
  }

  subscribeLoadingBarStatus(): Observable<string> {
    return this.showLoadingBar.asObservable();
  }

  publishActiveCompanyAccount(account: Account): void {
    this.activeCompanyAccount.next(account);
  }

  subscribeActiveCompanyAccount(): Observable<Account> {
    return this.activeCompanyAccount.asObservable();
  }

  publishProfileImage(image: string): void {
    this.profileImage.next(image);
  }

  subscribeProfileImage(): Observable<string> {
    return this.profileImage.asObservable();
  }

  publishMenuState(menuState: boolean): void {
    this.showResponsiveMenu.next(menuState);
  }

  subscribeMenuState(): Observable<boolean> {
    return this.showResponsiveMenu.asObservable();
  }

  publishErrorResult(errorResult: ErrorResult): void {
    this.errorResult.next(errorResult);
  }

  subscribeErrorResult(): Observable<ErrorResult> {
    return this.errorResult.asObservable();
  }

  publishUserRoleActions(userActions: number[]): void {
    this.userActions.next(userActions);
  }

  subscribeUserRoleActions(): Observable<number[]> {
    return this.userActions.asObservable();
  }

  publishSubscriptionActions(subscriptionActions: number[]): void {
    this.subscriptionActions.next(subscriptionActions);
  }

  subscribeSubscriptionActions(): Observable<number[]> {
    return this.subscriptionActions.asObservable();
  }

  publishOnBoardCompanyAccounts(accounts: Account[]): void {
    this.onboardCompanyAccounts.next(accounts);
  }

  subscribeOnBoardedCompanyAccounts(): Observable<Account[]> {
    return this.onboardCompanyAccounts.asObservable();
  }

  publishOverlayState(overlayState: boolean): void {
    this.showOverlay.next(overlayState);
  }

  subscribeOverlayState(): Observable<boolean> {
    return this.showOverlay.asObservable();
  }

  publishAccountingSystemIntegrationStatus(accountStatus: boolean): void {
    this.accountSystemIntegrationStatus.next(accountStatus);
  }

  subscribeAccountingSystemIntegrationStatus(): Observable<boolean> {
    return this.accountSystemIntegrationStatus.asObservable();
  }

  publishAccountingSystemReconnectionStatus(accountStatus: boolean): void {
    this.accountSystemReConnectionStatus.next(accountStatus);
  }

  subscribeAccountingSystemReconnectionStatus(): Observable<boolean> {
    return this.accountSystemReConnectionStatus.asObservable();
  }

  publishAccountingSystemIntegration(accountingSystemIntegration: AccountingSystemIntegration): void {
    this.accountSystemIntegration.next(accountingSystemIntegration);
  }

  subscribeAccountingSystemIntegration(): Observable<AccountingSystemIntegration> {
    return this.accountSystemIntegration.asObservable();
  }

  publishErrorMessageClose(status: string): void {
    this.closeErrorPopUp.next(status);
  }

  subscribeErrorMessageClose(): Observable<string> {
    return this.closeErrorPopUp.asObservable();
  }

  publishShowFullScreenState(fullScreenState: boolean): void {
    this.showFullscreen.next(fullScreenState);
  }

  subscribeShowFullScreenState(): Observable<boolean> {
    return this.showFullscreen.asObservable();
  }

  publishReloadMainMenu(loadState: boolean): void {
    this.reloadMainMenu.next(loadState);
  }

  subscribeReloadMainMenu(): Observable<boolean> {
    return this.reloadMainMenu.asObservable();
  }

  publishAccountIntegrationStatus(accountIntegrationStatus: AccountIntegrationStatus): void {
    this.accountIntegrationStatus.next(accountIntegrationStatus);
  }

  subscribeAccountIntegrationStatus(): Observable<AccountIntegrationStatus> {
    return this.accountIntegrationStatus.asObservable();
  }

  publishCompanyImage(image: string): void {
    this.companyImage.next(image);
  }

  subscribeCompanyImage(): Observable<string> {
    return this.companyImage.asObservable();
  }

  publishSwitchedAccount(account: Account): void {
    this.switchedAccount.next(account);
  }

  subscribeSwitchedAccount(): Observable<Account> {
    return this.switchedAccount.asObservable();
  }

  publishSelectedCurrency(currencyRate: Currency): void {
    this.selectedCurrency.next(currencyRate);
  }

  subscribeSelectedCurrency(): Observable<Currency> {
    return this.selectedCurrency.asObservable();
  }

  publishSearchTextReset(searchText: string): void {
    this.searchText.next(searchText);
  }

  subscribeSearchTextReset(): Observable<string> {
    return this.searchText.asObservable();
  }

  publishCanLoadHome(checkedStatus: boolean): void {
    this.canLoadHome.next(checkedStatus);
  }

  subscribeCanLoadHome(): Observable<boolean> {
    return this.canLoadHome.asObservable();
  }

  publishSelectedDashboardMonthStatus(isCurrentMonth: boolean): void {
    this.currentSelectedMonthStatus = isCurrentMonth;
    this.selectedMonthStatus.next(isCurrentMonth);
  }

  subscribeSelectedDashboardMonthStatus(): Observable<boolean> {
    return this.selectedMonthStatus.asObservable();
  }

  getSelectedDashboardMonthStatus(): boolean | undefined {
    return this.currentSelectedMonthStatus;
  }

  resetSelectedDashboardMonthStatus(): void {
    this.publishSelectedDashboardMonthStatus(false);
    this.currentSelectedMonthStatus = undefined;
  }

  publishMenuToggleEvent(menuState: boolean): void {
    this.menuToggleEvent.next(menuState);
  }

  subscribeMenuToggleEvent(): Observable<boolean> {
    return this.menuToggleEvent.asObservable();
  }

  publishActiveSubscription(subscription: AccountSubscriptionHistory): void {
    this.activeSubscription.next(subscription);
  }

  subscribeActiveSubscription(): Observable<AccountSubscriptionHistory> {
    return this.activeSubscription.asObservable();
  }
}
