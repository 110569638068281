import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { LoadingBarComponent } from 'src/app/modules/shared/components/loading-bar/loading-bar.component';
import { UserInterfaceService } from 'src/app/modules/shared/services/user-interface.service';
import { DotCheckProgressComponent } from 'src/app/modules/shared/components/dot-check-progress/dot-check-progress.component';
import { LocalizationModule } from 'src/app/modules/localization/localization.module';
import { SuccessModalComponent } from 'src/app/modules/shared/components/success-modal/success-modal.component';
import { ErrorModalComponent } from 'src/app/modules/shared/components/error-modal/error-modal.component';
import { DynamicTemplateDirective } from 'src/app/modules/shared/directives/dynamic-template.directive';
import { WelcomeUserComponent } from 'src/app/modules/shared/components/welcome-user/welcome-user.component';
import { LanguageDataService } from 'src/app/modules/shared/services/language-data.service';
import { BrregDataService } from 'src/app/modules/shared/services/brreg-data-service';
import { NavTabsComponent } from 'src/app/modules/shared/components/nav-tabs/nav-tabs.component';
import { TabComponent } from 'src/app/modules/shared/components/nav-tabs/tab/tab.component';
import { DynamicTabsDirective } from 'src/app/modules/shared/directives/dynamic-tabs.directive';
import { CapassaTabComponent } from 'src/app/modules/shared/components/capassa-tab/capassa-tab.component';
import { ButtonListComponent } from 'src/app/modules/shared/components/button-list/button-list.component';
import { ProfileImageEditComponent } from 'src/app/modules/shared/components/profile-image-edit/profile-image-edit.component';
import { AccountDataService } from 'src/app/modules/shared/services/account-user-data.service';
import { CurrencyFormatPipe } from 'src/app/modules/shared/pipes/currency-format.pipe';
import { NumberFormatPipe } from 'src/app/modules/shared/pipes/number-format.pipe';
import { DecimalFormatPipe } from 'src/app/modules/shared/pipes/decimal-format.pipe';
import { BackButtonComponent } from 'src/app/modules/shared/components/back-button/back-button.component';
import { CapassaBaseComponent } from 'src/app/modules/shared/components/capassa-base/capassa-base.component';
import { NoAccessComponent } from 'src/app/modules/shared/components/no-access/no-access.component';
import { DateFormatPipe } from 'src/app/modules/shared/pipes/date-format.pipe';
import { InviteCompanyCEOComponent } from 'src/app/modules/shared/components/invite-company-ceo/invite-company-ceo.component';
import { NoAccountingSystemAccessComponent } from 'src/app/modules/shared/components/no-accounting-system-access/no-accounting-system-access.component';
import { NoAccessBaseComponent } from 'src/app/modules/shared/components/no-access-base/no-access-base.component';
import { SanitizedHtmlPipe } from 'src/app/modules/shared/pipes/sanitized-html.pipe';
import { CompanyImageEditComponent } from 'src/app/modules/shared/components/company-image-edit/company-image-edit.component';
import { QuarterPickerComponent } from 'src/app/modules/shared/components/quarter-picker/quarter-picker.component';
import { CapassaIndexComponent } from 'src/app/modules/shared/components/capassa-index/capassa-index.component';
import { DataVisualizationModule } from 'src/app/modules/data-visualization/data-visualization.module';
import { CfoRecommendationCardComponent } from 'src/app/modules/shared/components/cfo-recommendation-card/cfo-recommendation-card.component';
import { CfoRecommendationDoneStatusComponent } from 'src/app/modules/shared/components/cfo-recommendation-done-status/cfo-recommendation-done-status.component';
import { ReminderCardComponent } from 'src/app/modules/shared/components/reminder-card/reminder-card.component';
import { WelcomeCardComponent } from 'src/app/modules/shared/components/welcome-card/welcome-card.component';
import { MonthlyReportCardComponent } from 'src/app/modules/shared/components/monthly-report-card/monthly-report-card.component';
import { TriggerBaseAdviceCardComponent } from 'src/app/modules/shared/components/trigger-base-advice-card/trigger-base-advice-card.component';
import { AdvanceTaxCardComponent } from 'src/app/modules/shared/components/advance-tax-card/advance-tax-card.component';
import { TaxCardNoAccountingSystemComponent } from 'src/app/modules/shared/components/tax-card-no-accounting-system/tax-card-no-accounting-system.component';
import { HolidayPayCardComponent } from 'src/app/modules/shared/components/holiday-pay-card/holiday-pay-card.component';
import { TaxCardAccountingSytemComponent } from 'src/app/modules/shared/components/tax-card-accounting-system/tax-card-accounting-system.component';
import { LostConnectionPopUpComponent } from 'src/app/modules/shared/components/lost-connection-pop-up/lost-connection-pop-up.component';
import { BudgetSaveModalComponent } from 'src/app/modules/shared/components/budget-save-modal/budget-save-modal.component';
import { ValueTrendComponent } from 'src/app/modules/shared/components/value-trend/value-trend.component';
import { CurrencyDataService } from 'src/app/modules/shared/services/currency-data.service';
import { CurrencyInfoComponent } from 'src/app/modules/shared/components/currency-info/currency-info.component';
import { BreadcrumbComponent } from 'src/app/modules/shared/components/breadcrumb/breadcrumb.component';
import { CapassaFileManagerComponent } from 'src/app/modules/shared/components/capassa-file-manager/capassa-file-manager.component';
import { CapassaFileManagerProgressComponent } from 'src/app/modules/shared/components/capassa-file-manager-progress/capassa-file-manager-progress.component';
import { ReportShareComponent } from 'src/app/modules/shared/components/report-share/report-share.component';
import { AnalyticsDataService } from 'src/app/modules/shared/services/analytics-data.service';
import { GoogleAnalyticsTagsManagerService } from 'src/app/modules/shared/services/google-analytics-tags-manager.service';
import { NoSubscriptionComponent } from 'src/app/modules/shared/components/no-subscription/no-subscription.component';

export const sharedModuleComponents = [
  LoadingBarComponent, DotCheckProgressComponent, SuccessModalComponent, ErrorModalComponent, DynamicTemplateDirective,
  WelcomeUserComponent, NavTabsComponent, TabComponent, DynamicTabsDirective, CapassaTabComponent, ButtonListComponent,
  ProfileImageEditComponent, CurrencyFormatPipe, NumberFormatPipe, DecimalFormatPipe, BackButtonComponent, CapassaBaseComponent,
  NoAccessComponent, DateFormatPipe, InviteCompanyCEOComponent, NoAccountingSystemAccessComponent, NoAccessBaseComponent,
  SanitizedHtmlPipe, CompanyImageEditComponent, QuarterPickerComponent, CapassaIndexComponent, CfoRecommendationCardComponent,
  CfoRecommendationDoneStatusComponent, ReminderCardComponent, WelcomeCardComponent, MonthlyReportCardComponent,
  TriggerBaseAdviceCardComponent, AdvanceTaxCardComponent, TaxCardNoAccountingSystemComponent, HolidayPayCardComponent,
  TaxCardAccountingSytemComponent, BudgetSaveModalComponent, ValueTrendComponent, LostConnectionPopUpComponent,
  CurrencyInfoComponent, BreadcrumbComponent, CapassaFileManagerComponent, CapassaFileManagerProgressComponent, 
  ReportShareComponent, NoSubscriptionComponent
];

@NgModule({
  declarations: [...sharedModuleComponents],
  imports: [
    CommonModule,
    NgbModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    DataVisualizationModule,
    LocalizationModule.forChild('shared')
  ],
  providers: [UserInterfaceService, LanguageDataService, BrregDataService, AccountDataService, CurrencyDataService, DecimalPipe,
    AnalyticsDataService, GoogleAnalyticsTagsManagerService],
  exports: [
    DotCheckProgressComponent, SuccessModalComponent, ErrorModalComponent, DynamicTemplateDirective,
    WelcomeUserComponent, NavTabsComponent, TabComponent, DynamicTabsDirective, CapassaTabComponent,
    ButtonListComponent, ProfileImageEditComponent, CurrencyFormatPipe, NumberFormatPipe, DecimalFormatPipe,
    BackButtonComponent, NoAccessComponent, DateFormatPipe, NoAccountingSystemAccessComponent, NoAccessBaseComponent,
    SanitizedHtmlPipe, CompanyImageEditComponent, CKEditorModule, QuarterPickerComponent, CapassaIndexComponent,
    CfoRecommendationCardComponent, CfoRecommendationDoneStatusComponent, ReminderCardComponent,
    WelcomeCardComponent, MonthlyReportCardComponent, TriggerBaseAdviceCardComponent, BudgetSaveModalComponent,
    AdvanceTaxCardComponent, TaxCardNoAccountingSystemComponent, HolidayPayCardComponent, TaxCardAccountingSytemComponent,
    ValueTrendComponent, CurrencyInfoComponent, BreadcrumbComponent, CapassaFileManagerComponent, CapassaFileManagerProgressComponent,
    ReportShareComponent
  ]
})

export class SharedModule {
}
