import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { Constants, RouteLinkConstants } from 'src/app/app.constants';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { B2CUser } from 'src/app/modules/core/user-service/user.model';
import { UserAction } from 'src/app/modules/shared/globals';
import { Account } from 'src/app/modules/shared/models/account';
import { AccountSubscriptionHistory } from 'src/app/modules/shared/models/account-subscription-history';
import { CFORecommendation } from 'src/app/modules/shared/models/cfo-recommendation';
import { KeywordResult } from 'src/app/modules/shared/models/keyword-result';
import { SearchKeyword } from 'src/app/modules/shared/models/search-keyword';
import { SubscriptionTypes } from 'src/app/modules/shared/models/subscription-types.enum';
import { AnalyticsDataService } from 'src/app/modules/shared/services/analytics-data.service';
import { SearchService } from 'src/app/modules/shared/services/search-service';
import { SharedStorageService } from 'src/app/modules/shared/services/shared-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('keywordSearch', { static: true }) keywordSearch: ElementRef;
  searchText: string;
  roleId: string;
  filteredSearchTexts: KeywordResult[];
  userActionsSubscription: Subscription;
  searchTextResetSubscription: Subscription;
  keywordsServiceSubscription: Subscription;
  showLoader = false;
  pageCount = 0;
  totalPages = 0;
  pageSize = 20;
  showList = false;
  stopSearch = true;
  notificationVisible: boolean = false;
  subscribeLoggedInB2CUser: Subscription;
  registrationB2cUser: B2CUser;
  isAvailableUnreadNotifications: boolean = false;
  navigationSubscription: Subscription;
  activeSubscription: Subscription;
  onboardedAccounts: Account[];
  resizeSubscription: () => void;
  noOfRemainingDays: number;
  trialBannerVisible: boolean = false;
  userActionSubscription: Subscription;
  canViewSubscriptions = true;

  constructor(private appStateService: AppStateService, private searchService: SearchService, private router: Router,
    private analyticsDataService: AnalyticsDataService, private sharedStorageService: SharedStorageService) { }

  ngOnInit(): void {
    this.searchTextResetSubscription = this.appStateService.subscribeSearchTextReset().subscribe(searchText => {
      this.searchText = searchText;
    })

    this.subscribeLoggedInB2CUser = this.appStateService.subscribeLoggedInB2CUser().subscribe((user: B2CUser) => {
      if (user && user.id) {
        this.registrationB2cUser = user;
      }
    });

    this.userActionSubscription = this.appStateService.subscribeUserRoleActions().subscribe(actions => {
      this.canViewSubscriptions = actions.includes(UserAction.ViewSubscriptions);
    });

    this.activeSubscription = this.appStateService.subscribeActiveSubscription().subscribe((subscription: AccountSubscriptionHistory) => {
      if (subscription)
        this.setNoOfRemainingDays(subscription);
    });

    this.checkUnreadNotificationsAvailable();

    fromEvent(this.keywordSearch.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      })
      , filter(res => res.length > 2)
      , debounceTime(1000)
      , distinctUntilChanged()
    ).subscribe((text: string) => {

      this.searchResultByKeyWord(text, 0, false);
    });

    this.navigationSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.notificationVisible = false;
        this.toggleInformationCenterOverlay();
      }
    });

    this.resizeSubscription = this.toggleInformationCenterOverlay.bind(this);
    window.addEventListener('resize', this.resizeSubscription);
  }

  goToLink(): void {
    this.router.navigate([RouteLinkConstants.landingPage]);
  }

  ngOnDestroy(): void {
    if (this.userActionsSubscription) { this.userActionsSubscription.unsubscribe(); }
    if (this.searchTextResetSubscription) { this.searchTextResetSubscription.unsubscribe(); }
    if (this.subscribeLoggedInB2CUser) { this.subscribeLoggedInB2CUser.unsubscribe(); }
    if (this.navigationSubscription) { this.navigationSubscription.unsubscribe(); }
    if (this.activeSubscription) { this.activeSubscription.unsubscribe(); }
    if (this.userActionSubscription) { this.userActionSubscription.unsubscribe(); }
    window.removeEventListener('resize', this.resizeSubscription);
  }

  setNoOfRemainingDays(subscription: AccountSubscriptionHistory): void {
    const currentSelectedAccountId = this.sharedStorageService.getAccountId();
    if (subscription?.typeId === SubscriptionTypes.Trial) {
      const trialBannerVisibility = this.sharedStorageService.getTrialBannerVisibility(currentSelectedAccountId);
      const today = new Date();
      const endDate = new Date(subscription?.endDate);
      const remainingTime = endDate.getTime() - today.getTime();
      this.noOfRemainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
      if (trialBannerVisibility === 'null') {
        if (endDate > today) {
          this.trialBannerVisible = true;
        } else {
          this.trialBannerVisible = false;
        }
      } else {
        const item = JSON.parse(trialBannerVisibility);
        const now = new Date();

        if (now.getTime() > item.expiry) {
          this.sharedStorageService.removeTrialBannerVisibility(currentSelectedAccountId);
          this.trialBannerVisible = true;
        } else {
          this.trialBannerVisible = false;
        }
      }
    } else {
      this.trialBannerVisible = false;
    }
  }

  closeTrialBanner(): void {
    this.trialBannerVisible = false;
    const currentSelectedAccountId = this.sharedStorageService.getAccountId();
    this.sharedStorageService.addTrialBannerVisibility(this.trialBannerVisible, currentSelectedAccountId);
  }

  goToSelectedRoute(keywordResult: KeywordResult): void {
    this.router.navigateByUrl(keywordResult.route)
      .finally(() => {
        this.searchText = keywordResult.description;
        this.showList = false;
      });
  }

  showLeftMenu(): void {
    this.appStateService.publishMenuState(true);
  }

  showMore(): void {
    this.pageCount++;
    this.searchResultByKeyWord(this.searchText, this.pageCount, true);
  }

  searchResultByKeyWord(searchText: string, pageNumber: number, showMoreResult: boolean): void {

    this.showLoader = true;
    const searchKeyword: SearchKeyword = {
      "searchText": searchText,
      "pageNumber": pageNumber,
      "pageSize": this.pageSize
    };

    this.searchService.getResultByKeyWord(searchKeyword).subscribe(keywordResult => {
      this.showLoader = false;
      this.filteredSearchTexts = keywordResult;
      this.showList = true;

      if (keywordResult) {
        if (showMoreResult) {
          this.filteredSearchTexts = this.filteredSearchTexts.concat(keywordResult);
          this.showList = true;
          this.showLoader = false;
        } else {
          this.filteredSearchTexts = keywordResult;
          this.showList = true;
          this.showLoader = false;
        }
      } else {
        this.showList = false;
        this.totalPages = 0;
        this.pageCount = 0;
        this.filteredSearchTexts = [];
        this.showLoader = false;
      }
    },
      (err: any) => {
        this.showLoader = false;
      })
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    if (this.showList && event.srcElement.id !== 'keywordSearch') {
      this.pageCount = 0;
      this.stopSearch = true;
      this.showList = false;
      event.stopPropagation();
    }
  }

  checkUnreadNotificationsAvailable(): void {
    if (this.registrationB2cUser?.registrationUserId) {
      this.analyticsDataService.getNotifications(this.registrationB2cUser.registrationUserId, false, false, 0, 5).subscribe((cfoRecommendationsList: CFORecommendation[]) => {
        if (cfoRecommendationsList.length > 0) {
          this.isAvailableUnreadNotifications = true;
        }
      });
    }
  }

  onVisibilityChange(visible: boolean): void {
    this.notificationVisible = visible;
    this.toggleInformationCenterOverlay();
  }

  onNotificationsAvailableVisibilityChange(visible: boolean): void {
    this.isAvailableUnreadNotifications = visible;
  }

  toggleNotification(): void {
    this.notificationVisible = !this.notificationVisible;
    this.toggleInformationCenterOverlay();
  }

  toggleInformationCenterOverlay(): void {
    const mobileModeScreenSize = 575.98;
    const appBody = document.querySelector('.app-body');

    if (appBody) {
      if (window.innerWidth <= mobileModeScreenSize && this.notificationVisible) {
        appBody.classList.add('information-center-on');
      } else {
        appBody.classList.remove('information-center-on');
      }
    }
  }

  goToSubscription(): void {
    this.router.navigate([RouteLinkConstants.subscription]);
  }
}
