import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { Constants, RouteLinkConstants } from 'src/app/app.constants';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { B2CUser } from 'src/app/modules/core/user-service/user.model';
import { environment } from 'src/environments/environment';
import { LoadingBarStatus, UserAction, UserFlow, UserPreferenceType } from 'src/app/modules/shared/globals';
import { OnboardingCompany } from 'src/app/modules/shared/models/onboarding-company';
import { Account } from 'src/app/modules/shared/models/account';
import { CompanyDataService } from 'src/app/modules/shared/services/company-data.service';
import { BrowserStateService } from 'src/app/modules/shared/services/browser-state.service';
import { AccountDataService } from 'src/app/modules/shared/services/account-user-data.service';
import { UserFlowService } from 'src/app/modules/shared/services/user-flow.service';
import { InviteCompanyCEOComponent } from 'src/app/modules/shared/components/invite-company-ceo/invite-company-ceo.component';
import { PopupMessageService } from 'src/app/modules/shared/services/popup-message-service';
import { SelectedCompany } from 'src/app/models/selectedCompany';
import { UserPreference } from 'src/app/modules/shared/models/UserPreference';
import { UserDataService } from 'src/app/modules/core/user-service/user-data.service';
import { UserInterfaceService } from 'src/app/modules/shared/services/user-interface.service';
import { SharedStorageService } from 'src/app/modules/shared/services/shared-storage.service';

@Component({
  selector: 'app-left-panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.scss']
})

export class LeftPanelComponent implements OnInit, OnDestroy {
  @Input() onBoardedAccounts: Account[] = [];
  @Input() showAccounts = false;
  @Output() menuChanged: EventEmitter<string> = new EventEmitter();
  @Output() companyChanged: EventEmitter<SelectedCompany> = new EventEmitter();
  @Output() menuCollapsed: EventEmitter<boolean> = new EventEmitter();

  selectedAccount: Account;
  isLoading = true;
  showSettings = false;
  showManageTeam = false;
  showAnalytics = false;
  showResponsiveLeftMenu = false;
  showBudget = false;
  showReport = false;
  constants = Constants;
  registrationB2cUser: B2CUser;
  loggedInB2CUserSubscription: Subscription;
  languageSubscription: Subscription;
  onBoardedCompanyAccountsSubscription: Subscription;
  userActionSubscription: Subscription;
  onCompanyAddedSubscription: Subscription;
  menuStateSubscription: Subscription;
  activeAccountSubscription: Subscription;
  switchedAccountSubscription: Subscription;
  selectedCompanyAccountSubscription: Subscription;
  isLoadingCompany = false;
  faqPage = environment.faqPage;
  selectedMenuItem = this.constants.home;
  selectedSubMenuItem = '';
  canViewUserTeams = false;
  canViewInvitations = false;
  canViewUserDetails = false;
  canViewCompanyDetails = false;
  canViewReporting = true;
  canViewDashboard = true;
  canViewIntegrations = false;
  canViewInvestorMatrix = false;
  canInviteAnotherCompany = false;
  canViewPredictions = false;
  canViewKeyFinancial = false;
  canViewProfitAndLoss = true;
  canViewBalanceSheet = true;
  canViewPLBudget = false;
  canViewLiquidityBudget = false;
  canCreateBudget = false;
  canChangeBudget = false;
  canDeleteBudget = false;
  canExportBudget = false;
  canViewBudgetComparison = false;
  showMarketplace = false;
  canDeleteCompany = false;
  canViewDataRoom = false;
  userFlow: UserFlow;
  currentURL = '';
  isInitialLoad = true;
  isMenuCollapsed = false;
  userPreferenceSubscription: Subscription;
  userPreferences: UserPreference[] = [];
  menuCollapsedPreferenceId: string;
  menuCollapsedValues: any[] = [];
  account: Account;
  enableGotoLink = false;
  enableSetMenuSelectionForCurrentURL: boolean;
  profileImageLocation = Constants.leftPanel;
  canViewLiquidityReport = true;
  canViewSubscriptions = true;

  constructor(private companyDataService: CompanyDataService, private appStateService: AppStateService,
    private modalService: NgbModal, private router: Router, private browserStateService: BrowserStateService,
    private accountDataService: AccountDataService, private userFlowService: UserFlowService, private popupMessageService: PopupMessageService,
    private userDataService: UserDataService, private userInterface: UserInterfaceService, private sharedStorageService: SharedStorageService) { }

  ngOnInit(): void {
    this.userFlow = this.userFlowService.getFlow();
    const clearDashboardRef = this.clearDashboardCache();
    clearDashboardRef();

    this.loggedInB2CUserSubscription = this.appStateService.subscribeLoggedInB2CUser().subscribe((b2cUser: B2CUser) => {
      this.registrationB2cUser = b2cUser;
      this.loadSelectedCompanyAccount();
    });


    this.menuStateSubscription = this.appStateService.subscribeMenuState().subscribe((menuState: boolean) => {
      this.showResponsiveLeftMenu = menuState;
    });

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        // Show loading indicator
        this.currentURL = event.url;
        this.setMenuSelectionForCurrentURL();
      }
    });

    this.setMenuSelectionForCurrentURL();

    this.appStateService.subscribeReloadMainMenu().subscribe((reload: boolean) => {
      if (reload) {
        this.loadSelectedCompanyAccount();
      }
    });

    this.switchedAccountSubscription = this.appStateService.subscribeSwitchedAccount().subscribe((account: Account) => {
      this.selectedAccount = account;
    })

    this.userPreferenceSubscription = this.appStateService.subscribeUserPreferences().subscribe(result => {
      this.userPreferences = result;
      const menuCollapsedPreference = result.find(r => r.preferenceType === UserPreferenceType.Sidebar);
      if (menuCollapsedPreference && menuCollapsedPreference.preferenceValue) {
        this.initializeMenuCollapsed(menuCollapsedPreference);
      }
    });

    this.languageSubscription = this.appStateService.subscribeSelectedLanguage().subscribe((language: string) => {
      this.faqPage = language === 'nb' ? environment.faqPage.replace('en-US', 'nb-NO') : environment.faqPage;
    });
  }

  initializeMenuCollapsed(menuCollapsedPreference: any): any {
    this.menuCollapsedPreferenceId = menuCollapsedPreference.preferenceId;
    this.menuCollapsedValues = JSON.parse(menuCollapsedPreference.preferenceValue);
    this.isMenuCollapsed = this.menuCollapsedValues[0].isMenuCollapsed;
    this.menuCollapsed.emit(this.isMenuCollapsed);
    if (this.isMenuCollapsed) {
      this.showSettings = false;
      this.showAnalytics = false;
      this.showManageTeam = false;
      this.showBudget = false;
      this.showReport = false;
    }
  }

  onProfileImageEdit(isMenuCollapsed: boolean): void {
    this.isMenuCollapsed = isMenuCollapsed;
    this.menuCollapsed.emit(isMenuCollapsed);
  }

  updateMenuCollapsedPreference(): void {
    const menuData = [{ "isMenuCollapsed": this.isMenuCollapsed }];
    const menuCollapsedUserPreference = new UserPreference();
    menuCollapsedUserPreference.preferenceId = this.menuCollapsedPreferenceId;
    menuCollapsedUserPreference.userId = this.registrationB2cUser.registrationUserId;
    menuCollapsedUserPreference.accountId = this.account.id;
    menuCollapsedUserPreference.preferenceType = UserPreferenceType.Sidebar;
    menuCollapsedUserPreference.preferenceValue = JSON.stringify(menuData);

    this.userDataService.updateUserPreference(menuCollapsedUserPreference).subscribe((result: UserPreference) => {
      this.menuCollapsedPreferenceId = result.preferenceId;
      menuCollapsedUserPreference.preferenceId = this.menuCollapsedPreferenceId;
      this.publishUserPreference(menuCollapsedUserPreference, UserPreferenceType.Sidebar);
    }, () => {
      this.userInterface.showCommonErrorMessage();
    });
  }

  publishUserPreference(userPreference: UserPreference, userPreferenceType: UserPreferenceType): void {
    this.userPreferences = this.userPreferences.filter(item => item.preferenceType !== userPreferenceType);
    this.userPreferences.push(userPreference);
    this.appStateService.publishUserPreferences(this.userPreferences);
  }

  ngOnDestroy(): void {
    if (this.loggedInB2CUserSubscription) { this.loggedInB2CUserSubscription.unsubscribe(); }
    if (this.onBoardedCompanyAccountsSubscription) { this.onBoardedCompanyAccountsSubscription.unsubscribe(); }
    if (this.userActionSubscription) { this.userActionSubscription.unsubscribe(); }
    if (this.onCompanyAddedSubscription) { this.onCompanyAddedSubscription.unsubscribe(); }
    if (this.menuStateSubscription) { this.menuStateSubscription.unsubscribe(); }
    if (this.selectedCompanyAccountSubscription) { this.selectedCompanyAccountSubscription.unsubscribe(); }
    if (this.userPreferenceSubscription) this.userPreferenceSubscription.unsubscribe();
    if (this.languageSubscription) this.languageSubscription.unsubscribe();
  }

  setUserRights(): void {
    if (this.selectedAccount && this.registrationB2cUser.registrationUserId) {
      var dashboardActions = [UserAction.ViewOverviewDashboard, UserAction.ViewProfitabilityDashboard, UserAction.ViewBalanceSheetDashboard, 
        UserAction.ViewLiquidityDashboard];
      this.userActionSubscription = this.appStateService.subscribeUserRoleActions().subscribe(actions => {       
        //this.canViewDashboard = dashboardActions.some(item => actions.includes(item));
        this.canViewUserTeams = actions.includes(UserAction.ViewAllUsers);
        this.canViewInvitations = actions.includes(UserAction.ViewInvitations);
        this.canViewUserDetails = actions.includes(UserAction.ViewUser);
        this.canViewIntegrations = actions.includes(UserAction.AddAccountingIntegration)
        this.canViewReporting = actions.includes(UserAction.ViewMonthlyReport)
        this.canViewInvestorMatrix = actions.includes(UserAction.ViewAndExportConsolidatedFinancials);
        this.canInviteAnotherCompany = actions.includes(UserAction.InviteAnotherCompany);
        this.canViewPredictions = actions.includes(UserAction.ViewPredictions);
        this.canViewKeyFinancial = actions.includes(UserAction.ViewKeyFinancial);
        this.showMarketplace = actions.includes(UserAction.ViewMarketplace);
        this.canViewProfitAndLoss = actions.includes(UserAction.viewProfitLoss);
        this.canViewBalanceSheet = actions.includes(UserAction.viewBalanceSheet);
        this.canViewPLBudget = actions.includes(UserAction.ViewPLBudget);
        this.canViewLiquidityBudget = actions.includes(UserAction.ViewLiquidityBudget);
        this.canViewBudgetComparison = actions.includes(UserAction.ViewBudgetComparison);
        this.canDeleteCompany = actions.includes(UserAction.DeleteCompany);
        this.canViewCompanyDetails = actions.includes(UserAction.ViewCompany);
        this.canViewDataRoom = actions.includes(UserAction.ViewDataRoom);
        this.canViewLiquidityReport = actions.includes(UserAction.ViewLiquidityReport);
        this.canViewSubscriptions = actions.includes(UserAction.ViewSubscriptions);
      });
    }
  }

  loadSelectedCompanyAccount(): void {
    this.selectedCompanyAccountSubscription = this.appStateService.subscribeOnBoardedCompanyAccounts().subscribe((accounts: Account[]) => {
      if (accounts && accounts.length > 0) {
        this.onBoardedAccounts = accounts;
        this.selectedAccount = this.accountDataService.getSelectedAccount();
        if (this.selectedAccount) {
          this.selectedAccount = this.onBoardedAccounts?.find(x => x.id == this.selectedAccount.id)!;
        } else {
          this.selectedAccount = this.onBoardedAccounts?.find(x => x.isDefault)!;
          if (!this.selectedAccount) {
            this.selectedAccount = this.onBoardedAccounts[0];
          }
          this.accountDataService.addSelectedAccount(this.selectedAccount);
        }
        this.setUserRights();
      }
    });
  }

  goToLink(url: string): void {
    this.enableGotoLink = true;
    this.hideResponsiveMenu();
    this.selectedSubMenuItem = url;
    this.appStateService.publishSearchTextReset('');
    switch (url) {
      case this.constants.home:
        this.selectMenu(Constants.home);
        this.selectedMenuItem = url;
        this.router.navigate([RouteLinkConstants.landingPageContainer]);
        break;
      case this.constants.contactUs:
        this.selectMenu(Constants.contactUs);
        this.router.navigate([RouteLinkConstants.contactUs]);
        break;
      case this.constants.myTeam:
        this.router.navigate([RouteLinkConstants.myTeam]);
        break;
      case this.constants.invitation:
        this.router.navigate([RouteLinkConstants.invitation]);
        break;
      case this.constants.myProfile:
        this.router.navigate([RouteLinkConstants.myProfile]);
        break;
      case this.constants.companyProfile:
        this.router.navigate([RouteLinkConstants.companyProfile]);
        break;
      case this.constants.manageIntegration:
        this.selectMenu(Constants.manageIntegration);
        this.selectedMenuItem = url;
        this.router.navigate([RouteLinkConstants.manageIntegration]);
        break;
      case this.constants.dashboard:
        this.userFlowService.setFlow(UserFlow.DashboardIntegration);
        this.router.navigate([RouteLinkConstants.dashboard]);
        break;
      case this.constants.keyFinancial:
        this.userFlowService.setFlow(UserFlow.KeyFinancialHomeIntegration);
        this.router.navigate([RouteLinkConstants.keyFinancial]);
        break;
      case this.constants.investorMatrix:
        this.router.navigate([RouteLinkConstants.investorMatrix]);
        break;
      case this.constants.prediction:
        this.userFlowService.setFlow(UserFlow.PredictionIntegration);
        this.router.navigate([RouteLinkConstants.prediction]);
        break;
      case this.constants.marketplace:
        this.router.navigate([RouteLinkConstants.marketplace]);
        break;
      case this.constants.profitAndLoss:
        this.selectedMenuItem = this.constants.report;
        this.userFlowService.setFlow(UserFlow.ReportIntegration);
        this.router.navigate([RouteLinkConstants.reports]);
        break;
      case this.constants.budgeting:
        this.userFlowService.setFlow(UserFlow.BudgetIntegration);
        this.router.navigate([RouteLinkConstants.budgeting]);
        break;
      case this.constants.changeBudget:
        this.userFlowService.setFlow(UserFlow.ChangeBudgetIntegration);
        this.router.navigate([RouteLinkConstants.changeBudget]);
        break;
      case this.constants.balanceSheet:
        this.selectedMenuItem = this.constants.report;
        this.userFlowService.setFlow(UserFlow.BalanceSheetIntegration);
        this.router.navigate([RouteLinkConstants.balanceSheet]);
        break;
      case this.constants.liquidityReport:
        this.selectedMenuItem = this.constants.report;
        this.userFlowService.setFlow(UserFlow.LiquidityIntegration);
        this.router.navigate([RouteLinkConstants.liquidityReport]);
        break;
      case this.constants.budgetComparison:
        this.userFlowService.setFlow(UserFlow.BudgetComparisonIntegration);
        this.router.navigate([RouteLinkConstants.budgetComparison]);
        break;
      case this.constants.dataRoom:
        this.router.navigate([RouteLinkConstants.dataRoom]);
        break;
      case this.constants.subscription:
        this.selectedMenuItem = this.constants.settings;
        this.router.navigate([RouteLinkConstants.subscription]);
        break;
      default:
        window.open(url, '_blank');
        break;
    }
  }

  hideResponsiveMenu(): void {
    if (this.showResponsiveLeftMenu) {
      this.appStateService.publishMenuState(false);
    }
  }

  selectMenu(menuSection: string, toggle = true): void {
    this.selectedMenuItem = menuSection;
    let expandLeftMenu = false;
    switch (menuSection) {
      case this.constants.manageTeam:
        this.showSettings = false;
        this.showAnalytics = false;
        this.showBudget = false;
        this.showReport = false;
        this.showManageTeam = toggle ? !this.showManageTeam : true;
        expandLeftMenu = this.showManageTeam;
        break;
      case this.constants.settings:
        this.showManageTeam = false;
        this.showAnalytics = false;
        this.showBudget = false;
        this.showReport = false;
        this.showSettings = toggle ? !this.showSettings : true;
        expandLeftMenu = this.showSettings;
        break;
      case this.constants.analytics:
        this.showSettings = false;
        this.showManageTeam = false;
        this.showBudget = false;
        this.showReport = false;
        this.showAnalytics = toggle ? !this.showAnalytics : true;
        expandLeftMenu = this.showAnalytics;
        break;
      case this.constants.report:
        this.showSettings = false;
        this.showManageTeam = false;
        this.showAnalytics = false;
        this.showBudget = false;
        this.showAnalytics = false;
        this.showReport = toggle ? !this.showReport : true;
        expandLeftMenu = this.showReport;
        break;
      case this.constants.budget:
        this.showSettings = false;
        this.showManageTeam = false;
        this.showAnalytics = false;
        this.showReport = false;
        this.showBudget = toggle ? !this.showBudget : true;
        expandLeftMenu = this.showBudget;
        break;
      default:
        this.showSettings = false;
        this.showAnalytics = false;
        this.showManageTeam = false;
        this.showBudget = false;
        this.showReport = false;
        break;
    }

    if (expandLeftMenu) {
      this.isMenuCollapsed = false;
      this.menuCollapsed.emit(this.isMenuCollapsed);
    }

    if (this.enableSetMenuSelectionForCurrentURL !== undefined && this.enableSetMenuSelectionForCurrentURL) {
      if (!this.enableGotoLink) {
        this.userPreferenceSubscription = this.appStateService.subscribeUserPreferences().subscribe(result => {
          this.userPreferences = result;
          const menuCollapsedPreference = result.find(r => r.preferenceType === UserPreferenceType.Sidebar);
          if (menuCollapsedPreference && menuCollapsedPreference.preferenceValue) {
            this.enableSetMenuSelectionForCurrentURL = false;
            this.enableGotoLink = false;
            this.initializeMenuCollapsed(menuCollapsedPreference);
          }
        });
      }
    }
  }

  onBoardCompany(): void {
    if (this.router?.url?.includes('budgeting')) { return; }
    if (this.registrationB2cUser.id) {
      this.isLoadingCompany = true;
      this.appStateService.publishLoadingBarStatus(LoadingBarStatus.ShowProgressBar);

      this.companyDataService.getUserCompanies(this.registrationB2cUser.id).subscribe(
        (onboardingAccounts: OnboardingCompany[]) => {
          this.isLoadingCompany = false;
          if (onboardingAccounts && onboardingAccounts.length > 0) {
            this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
            this.router.navigate(['', { outlets: { overlayOutlet: RouteLinkConstants.onboardingCompany } }],
              { queryParams: { userFlow: UserFlow.AddCompany } });
          } else {
            this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
            this.browserStateService.setBrowserBackButtonForPage(RouteLinkConstants.informCEO, Constants.home);
            this.router.navigate([RouteLinkConstants.informCEO]);
          }
          this.hideResponsiveMenu();
        }, error => {
          this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
          this.isLoadingCompany = false;
          this.browserStateService.setBrowserBackButtonForPage(RouteLinkConstants.informCEO, Constants.home);
          this.router.navigate([RouteLinkConstants.informCEO]);
          this.hideResponsiveMenu();
        });
    }
  }

  onInviteAnotherCompany(): void {
    if (this.router?.url?.includes('budgeting')) { return; }
    this.popupMessageService.showComponentInPopup(InviteCompanyCEOComponent);
  }

  open(modal: any): void {
    this.modalService.open(modal);
  }

  setSelectedCompany(setDefault: boolean): void {
    const selectedCompany = new SelectedCompany();
    selectedCompany.selectedAccount = this.selectedAccount;
    selectedCompany.setDefault = setDefault;
    this.selectedMenuItem = this.constants.home;
    this.selectedSubMenuItem = '';
    this.appStateService.resetSelectedDashboardMonthStatus();
    this.companyChanged.emit(selectedCompany);
  }

  setMenuSelectionForCurrentURL(): void {
    this.enableSetMenuSelectionForCurrentURL = true;
    const urlParts = this.router.url.split('/');
    urlParts.forEach((urlPart, i) => {

      if (urlPart.indexOf('(') !== -1) {
        urlPart = urlPart.split('(')[0];
      } else if (urlPart.indexOf('?') !== -1) {
        if (urlPart.indexOf(RouteLinkConstants.noAccountingSystem) !== -1) {
          urlPart = urlPart.split('?')[1];
          urlPart = urlPart.split('&')[0];
          urlPart = urlPart.split('=')[1];
          urlPart = urlPart.split('%2F')[1];
        }
        urlPart = urlPart.split('?')[0];
      }
      // parent level menu item
      if (i === 1) {
        this.selectedMenuItem = urlPart;
        this.selectMenu(this.selectedMenuItem, false);
      }

      // child level menu item
      else if (i === 2 && RouteLinkConstants.noAccountingSystem !== urlPart) {
        this.selectedSubMenuItem = urlPart;

        if (this.selectedMenuItem === Constants.home) {
          // has to rewrite parent level menu item for some pages
          const routeLink = this.selectedMenuItem + '/' + this.selectedSubMenuItem;
          switch (routeLink) {
            case RouteLinkConstants.myProfile:
            case RouteLinkConstants.companyProfile:
              this.selectMenu(Constants.settings, false);
              break;
            case RouteLinkConstants.myTeam:
            case RouteLinkConstants.invitation:
              this.selectMenu(Constants.manageTeam, false);
              break;
            case RouteLinkConstants.addNewMember:
              this.selectedSubMenuItem = Constants.invitation;
              this.selectMenu(Constants.manageTeam, false);
              break;
            case RouteLinkConstants.manageIntegration:
              this.selectedMenuItem = Constants.manageIntegration;
              break;
          }
        } else if (this.selectedMenuItem === Constants.investor) {
          const routeLink = this.selectedMenuItem + '/' + this.selectedSubMenuItem;
          switch (routeLink) {
            case RouteLinkConstants.investorMatrix:
              this.selectedSubMenuItem = Constants.investorMatrix;
              this.selectMenu(Constants.analytics, false);
              break;
          }
        } else if (this.selectedMenuItem === Constants.subscription) {
          this.selectedSubMenuItem =  Constants.subscription;
          this.selectMenu(Constants.settings, false);        
        }
        else if (this.selectedMenuItem === Constants.analytics) {
          const routeLink = this.selectedMenuItem + '/' + this.selectedSubMenuItem;
          switch (routeLink) {
            case RouteLinkConstants.capassaIndexDetails:
              this.selectedSubMenuItem = Constants.keyFinancial;
              this.selectMenu(Constants.analytics, false);
              break;
            case RouteLinkConstants.reports:
              this.selectedSubMenuItem = Constants.profitAndLoss;
              this.selectMenu(Constants.report, false);
              break;
            case RouteLinkConstants.balanceSheet:
              this.selectedSubMenuItem = Constants.balanceSheet;
              this.selectMenu(Constants.report, false);
              break;
            case RouteLinkConstants.liquidityReport:
              this.selectedSubMenuItem = Constants.liquidityReport;
              this.selectMenu(Constants.report, false);
              break;
          }
        }
      }
    });
  }

  toggleMenu(): void {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this.updateMenuCollapsedPreference();
    if (this.isMenuCollapsed) {
      this.showSettings = false;
      this.showAnalytics = false;
      this.showManageTeam = false;
      this.showBudget = false;
      this.showReport = false;
    }
    this.menuCollapsed.emit(this.isMenuCollapsed);
  }

  clearDashboardCache(): any {
    const that = this;
    return function () {
      that.account = that.accountDataService.getSelectedAccount();
      if (that.account) {
        that.sharedStorageService.removeDashboardData(that.account.id);
        that.sharedStorageService.removeAnalyticsData(that.account.id);
      } else {
        setTimeout(() => {
          that.clearDashboardCache();
        }, 1000);
      }
    };
  }
}
