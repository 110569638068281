<nav class="navbar navbar-expand-md">
  <a class="navbar-brand capassa-logo" (click)="goToLink()"></a>
  <div class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault"
    aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation" (click)="showLeftMenu()">
    <span class="navbar-toggler-icon"></span>
  </div>

  <div class="app-main-search select-result">
    <input id="globalSearch" type="textarea" placeholder="{{'Search'| translate }}" #keywordSearch class="form-control"
      [(ngModel)]="searchText" maxlength="50" [ngClass]="{'loading':showLoader,'search-input' : !showLoader }"
      (focus)="this.stopSearch = false;">
    <input class="btn-mic" />
    <div class="p-r-c">
      <div *ngIf="showList && searchText && searchText.length > 2" class="select-company">
        <div class="links-container" *ngFor="let filteredSearchText of filteredSearchTexts">
          <div class="link-shortcut" (click)="goToSelectedRoute(filteredSearchText)">{{
            filteredSearchText.description }} </div>
        </div>
        <div *ngIf="totalPages > pageCount+1" class="nav-link" (click)="showMore()">{{"Show more" |
          translate }}
          <b>...</b>
        </div>
      </div>
    </div>

    <!-- <div class="btn-mic"  class="input-search"></div> -->
  </div>
  <div class="navbar-user-info">
    <app-currency-info></app-currency-info>
    <div class="information-center">
      <span [ngClass]="{'bell-icon-outline ': isAvailableUnreadNotifications,
      'bell-icon-without-dot-outline': !isAvailableUnreadNotifications }" class="information-center-icon"
        (click)="toggleNotification()"></span>
      <app-notification *ngIf="notificationVisible" (visibilityChange)="onVisibilityChange($event)"
        (notificationsAvailableVisibilityChange)="onNotificationsAvailableVisibilityChange($event)"></app-notification>
    </div>
    <app-profile-settings></app-profile-settings>
  </div>

  <!-- Subscription Expiry Trial Banner Begin Here -->
  <div class="trial-banner" *ngIf="trialBannerVisible">
    <div class="trial-banner__content">
      <span class="trial-banner__icon">i</span>
      <p class="trial-banner__message">
        {{'Your trial period ends in' | translate}}<strong> {{noOfRemainingDays}} {{'days' | translate}}</strong>. {{'Check out our subscription plans.' | translate}}
      </p>
    </div>
    <button *ngIf="canViewSubscriptions" class="trial-banner__button btn btn-primary btn-block" (click)="goToSubscription()">{{'Check subscriptions' | translate}}</button>
    <div class="trial-banner__separator"></div>
    <button class="trial-banner__close" (click)="closeTrialBanner()">
      <svg xmlns="http://www.w3.org/2000/svg" width="14.121" height="14.939" viewBox="0 0 14.121 14.939">
        <path id="Path_83442" data-name="Path 83442" d="M342,36.8,330,23.98m12,0L330,36.8"
          transform="translate(-328.939 -22.92)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" />
      </svg>
    </button>
  </div>
  <!-- Subscription Expiry Trial Banner End Here -->
</nav>